import React from "react"

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="71"
    height="60"
    viewBox="0 0 71 60"
  >
    <path
      fill="#000000"
      d="M708.096064,59.2695981 L708.730482,58.63518 C712.246269,55.1193937 717.948725,55.1222593 721.466333,58.6398669 L721.554222,58.7277565 C725.070423,62.243957 725.070875,67.9516419 721.558909,71.4636071 L709.510278,83.5122388 C708.729229,84.2932874 707.462899,84.2932874 706.681851,83.5122388 L694.633219,71.4636071 C691.121254,67.9516419 691.121705,62.243957 694.637906,58.7277565 L694.725795,58.6398669 C698.243403,55.1222593 703.94586,55.1193937 707.461646,58.63518 L708.096064,59.2695981 Z M718.730482,68.63518 C720.68025,66.6854121 720.679999,63.5103872 718.725795,61.5561836 L718.637906,61.468294 C716.681759,59.5121476 713.511962,59.5105547 711.558909,61.4636071 L709.510278,63.5122388 C708.729229,64.2932874 707.462899,64.2932874 706.681851,63.5122388 L704.633219,61.4636071 C702.680166,59.5105547 699.510369,59.5121476 697.554222,61.468294 L697.466333,61.5561836 C695.512129,63.5103872 695.511878,66.6854121 697.461646,68.63518 L708.096064,79.2695981 L718.730482,68.63518 Z M665,88.5714286 L665,82 C665,80.8954305 665.895431,80 667,80 C668.104569,80 669,80.8954305 669,82 L669,84.7619048 L677.62069,76.5517241 C678.393103,75.816092 679.606897,75.816092 680.37931,76.5517241 L701.37931,96.5517241 C702.179171,97.3134962 702.210048,98.5794497 701.448276,99.3793103 C700.686504,100.179171 699.42055,100.210048 698.62069,99.4482759 L698,98.8571429 L698,122 C698,123.104569 697.104569,124 696,124 L662,124 C660.895431,124 660,123.104569 660,122 L660,98.8571429 L659.37931,99.4482759 C658.57945,100.210048 657.313496,100.179171 656.551724,99.3793103 C655.789952,98.5794497 655.820829,97.3134962 656.62069,96.5517241 L665,88.5714286 Z M664,95.047619 L664,120 L694,120 L694,95.047619 L679,80.7619048 L664,95.047619 Z"
      transform="translate(-656 -56)"
    />
  </svg>
)

export default Logo
